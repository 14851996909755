<template>
	<div class="ns-brand">
		<el-carousel height="400px" v-loading="loadingAd">
			<el-carousel-item v-for="item in adList" :key="item.adv_id"><el-image :src="$img(item.adv_image)" fit="cover" @click="$router.pushToTab(item.adv_url.url)" /></el-carousel-item>
		</el-carousel>

		<!-- 品牌列表 -->
		
		<div class="ns-brand-box" v-loading="loading">
			<div class="brands-tab-query">
				<ul>
					<li  v-for="(item, index) in brandList" :key="index">
						<a :href="'#'+index" class="active">{{ index }}</a>
					</li>
				</ul>
			</div>
			<div class="brands-tab-body">
				<div class="brands-tab-items" v-for="(item, index) in brandList" :key="index">
					<div class="brands-tab-item">
						<a :id="index"></a>
						<h2>{{ index }}</h2>
						<div class="brands-tab-item-list">
							<ul>
								<li  v-for="(items, indexs) in item" :key="indexs">
									<a href="javasript:;" @click="$router.pushToTab({ path: '/list', query: { brand_id: items.brand_id } })" :title="items.brand_name">{{items.brand_name}}</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div style="display:none">
				<div class="ns-brand-title-wrap ns-text-align">
					<p class="ns-brand-title">品牌专区</p>
					<img src="@/assets/images/goods/split.png" alt="" />
					<p class="ns-brand-en">Brand zone</p>
				</div>

			<!-- 	<div class="ns-brand-list" v-if="brandList.length > 0">
					<div class="ns-brand-li" v-for="(item, index) in brandList" :key="index" @click="$router.pushToTab({ path: '/list', query: { brand_id: item.brand_id } })">
						<div class="ns-brand-wrap">
							<el-image fit="scale-down" :src="$img(item.image_url)" lazy @error="imageError(index)"></el-image>
							<p :title="item.brand_name">{{ item.brand_name }}</p>
						</div>
					</div>
				</div>

				<div class="empty-wrap" v-if="brandList.length <= 0"><div class="ns-text-align">暂无更多品牌,去首页看看吧</div></div>

				<div class="pager">
					<el-pagination
						background 
						:pager-count="5" 
						:total="total" 
						prev-text="上一页" 
						next-text="下一页" 
						:current-page.sync="currentPage" 
						:page-size.sync="pageSize" 
						@size-change="handlePageSizeChange" 
						@current-change="handleCurrentPageChange" 
						hide-on-single-page
					></el-pagination>
				</div> -->
			</div>
		</div>
	</div>
</template>

<script>
import { brandList } from '@/api/goods/goods';
import { mapGetters } from 'vuex';
import { adList } from '@/api/website';

export default {
	name: 'brand',
	components: {},
	data: () => {
		return {
			total: 0,
			currentPage: 1,
			pageSize: 20,
			brandList: [],
			siteId: 0,
			loading: true,
			loadingAd: true,
			adList: []
		};
	},
	created() {
		this.getAdList();
		this.getBrandList();
	},
	computed: {
		...mapGetters(['defaultBrandImage'])
	},
	methods: {
		handlePageSizeChange(size) {
			this.pageSize = size;
			this.refresh();
		},
		handleCurrentPageChange(page) {
			this.currentPage = page;
			this.refresh();
		},
		refresh() {
			this.loading = true;
			this.getBrandList();
		},
		getBrandList() {
			brandList({
				page_size: this.pageSize,
				page: this.currentPage,
				site_id: this.siteId,
				is_brand:1
			})
				.then(res => { 
					this.brandList = res.data;
				//	this.total = res.data.count;
					this.loading = false;
				})
				.catch(err => {
					this.loading = false;
					this.$message.error(err.message);
				});
		},
		/**
		 * 图片加载失败
		 */
		imageError(index) {
			this.brandList[index].image_url = this.defaultBrandImage;
		},
		getAdList() {
			adList({ keyword: 'NS_PC_BRAND' })
				.then(res => {
					this.adList = res.data.adv_list;
					for (let i = 0; i < this.adList.length; i++) {
						if (this.adList[i].adv_url) this.adList[i].adv_url = JSON.parse(this.adList[i].adv_url);
					}
					this.loadingAd = false;
				})
				.catch(err => {
					this.loadingAd = false;
				});
		}
	}
};
</script>
<style lang="scss" scoped>
.ns-text-align {
	text-align: center;
}

.ns-brand-box {
	width: 100%;
	background: #ffffff;
	> div {
		width: $width;
		margin: 0 auto;
	}
}
.ns-brand-title-wrap {
	padding-top: 54px;
	.ns-brand-title {
		font-size: 26px;
		font-weight: 600;
		line-height: 30px;
	}
	.ns-brand-en {
		font-size: 24px;
		font-weight: 600;
		color: #383838;
		opacity: 0.2;
		text-transform: uppercase;
		letter-spacing: 5px;
		line-height: 30px;
	}
}
.ns-brand-list {
	display: flex;
	flex-wrap: wrap;
	padding-top: 30px;

	.ns-brand-li {
		width: 20%;
		padding: 8px 6px;
		box-sizing: border-box;

		.ns-brand-wrap {
			width: 100%;
			border: 1px solid #f1f1f1;
			overflow: hidden;
			color: #303133;
			padding: 15px;
			box-sizing: border-box;
			cursor: pointer;
			text-align: center;

			.el-image {
				width: 100%;
				height: 120px;
				line-height: 120px;
			}

			p {
				font-size: 22px;
				color: #383838;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
	}
}

.empty-wrap {
	margin-top: 30px;
}
</style>
<style lang="scss">
.ns-brand {
	.el-carousel {
		.el-image__inner {
			width: auto;
		}
	}
	.el-carousel__arrow--right {
		right: 60px;
	}
}
.brands-tab-query{ padding-top: 20px}
.brands-tab-query ul li{list-style:none;display:inline-block;float:left;height:30px;line-height:30px;margin-right:1px;}
.brands-tab-query ul li a{padding:0 10px;border:1px solid #c7c7c7;height:25px;line-height:25px;cursor:pointer;}
.brands-tab-query ul li a:hover,.brands-tab-query ul li a.active{background:#FF510C;color:#fff;}


.brands-tab-body {margin-top:12px;}
.brands-tab-body .brands-tab-items .brands-tab-item h2 {height:18px; line-height:18px; margin-bottom:10px; font-size:14px; color:#828181;}
.brands-tab-body .brands-tab-items .brands-tab-item .brands-tab-item-list {border:none; margin:0; padding:2px 0;}

.brands-tab-body .brands-tip {border:none;}
.brands-tab-body .brands-tip h2 {background:#d22929; font-size:18px; color:#fff;}
.brands-tab-body .brands-tip .brands-tip-list ul li a img {border:none;}

 .brands-tab-body{margin-top:10px;}
.brands-tab-body .brands-tip{float:right;width:183px;border:1px solid #c7c7c7;background:#fff;}
.brands-tab-body .brands-tip h2{display:block;background:#808080;text-align:center;height:40px;line-height:40px;color:#fff;}
.brands-tab-body .brands-tip .brands-tip-list{padding-bottom:10px;}
.brands-tab-body .brands-tip .brands-tip-list ul li{margin-top:20px;}
.brands-tab-body .brands-tip .brands-tip-list ul li a{display:block;text-align:center;cursor:pointer;}
.brands-tab-body .brands-tip .brands-tip-list ul li a img{border:1px solid #c7c7c7;}
.brands-tab-body .brands-tab-items{float:left;width:100%}
.brands-tab-body .brands-tab-items .brands-tab-item{margin-bottom:10px;}
.brands-tab-body .brands-tab-items .brands-tab-item h2{display:block;height:30px;line-height:25px;color:#828181;padding-left:30px;margin-bottom:5px;}
.brands-tab-body .brands-tab-items .brands-tab-item .brands-tab-item-list{border:1px solid #c7c7c7;border-radius:10px;background:#fff;overflow:hidden;margin-left:5px;}
.brands-tab-body .brands-tab-items .brands-tab-item .brands-tab-item-list ul li{ text-align:center;list-style:none; display:inline-block;float:left;height:30px;line-height:30px;width:20%;}
.brands-tab-body .brands-tab-items .brands-tab-item .brands-tab-item-list ul li a{height:30px;line-height:30px;width:15%;cursor:pointer;}


</style>
